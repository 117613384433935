import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Add, Close, Delete, Edit, FileCopyTwoTone } from "@mui/icons-material";
import {
  Button,
  Card,
  CardContent,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  MenuItem,
  Paper,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { useSnackbar } from "notistack";
import { debounce } from "lodash";
import {
  // clearState,
  lenderSelector,
  // setCurrentLender,
  setEditLenderPayload,
  updateCurrentlyEditingFee,
  updateCurrentLenderFees,
} from "../../../../store/slices/lenderSlice";
import { fCurrency } from "../../../../utils/formatNumber";
import { NumericFormatCustom } from "../../../../utils/currencyMaskFormat";
import { FEE_CALCULATION_TYPES, FEE_FREQUENCIES } from "../../../../constants";
import CriteriaContent from "../CriteriaContent";
import { PercentageFormatCustom } from "../../../../utils/percentageMaskFormat";

const Fee = ({ data, index: feeIndex, editLender, lender, editLenderLoading, addDialogIsOpen, setAddDialogIsOpen }) => {
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const lenderId = lender?._id;
  const {
    lender: lenderState,
    editLenderPayload,
    currentlyEditingFee,
    currentLenderFees,
  } = useSelector(lenderSelector);

  const [fee, setFee] = useState(null);

  useEffect(() => {
    if (data) setFee(data);
  }, [data]);

  const [editIsOpen, setEditIsOpen] = useState(addDialogIsOpen ?? false);

  const handleEditClose = () => {
    // dispatch(clearState());
    // dispatch(setCurrentLender(lenderState ?? lender));
    // setFee(lenderState?.fees[feeIndex]); // Reset the criteria count display for fee card
    setEditIsOpen(false);
    if (setAddDialogIsOpen) {
      setAddDialogIsOpen(false);
    }
  };

  const handleEditOpen = () => {
    dispatch(updateCurrentlyEditingFee(data));
    setEditIsOpen(true);
  };

  const handleEditDetails = (fees) => {
    dispatch(
      setEditLenderPayload({
        lenderId,
        body: {
          ...Object.fromEntries(Object.entries(lenderState).filter(([key]) => key !== "_id" && key !== "productTiers")),
          fees,
        },
      }),
    );
    // debounce(
    //   () =>
    //     editLender({
    //       lenderId,
    //       body: {
    //         ...Object.fromEntries(Object.entries(lender).filter(([key]) => key !== "_id" && key !== "productTiers")),
    //         fees,
    //       },
    //     }),
    //   500,
    // )();
  };

  const handleDebounced = useCallback(debounce(handleEditDetails, 500), []);

  const handleDeleteFee = () => {
    const fees = lenderState.fees.filter((f) => f._id !== fee._id);
    editLender({
      lenderId,
      body: {
        ...Object.fromEntries(Object.entries(lenderState).filter(([key]) => key !== "_id" && key !== "productTiers")),
        fees: fees,
      },
    });
  };

  const handleCloneFee = () => {
    const { _id, ...rest } = fee;
    rest.criteria = rest.criteria.map(({ _id, createdAt, updatedAt, __v, ...rest }) => rest);

    const newFees = [...lenderState.fees, rest];

    editLender({
      lenderId,
      body: {
        ...Object.fromEntries(Object.entries(lenderState).filter(([key]) => key !== "_id" && key !== "productTiers")),
        fees: newFees,
      },
    });
  };

  const handleAddCriteria = () => {
    const criteria = {
      productTier: null,
      label: "",
      object: "",
      attribute: "",
      condition: "",
      value: 0,
    };
    const { _id, ...rest } = currentlyEditingFee;
    const editedFeeWithCriteria = [
      ...currentlyEditingFee.criteria.map((c) => {
        const { _id, ...rest } = c;
        return rest;
      }),
    ];
    editedFeeWithCriteria.unshift(criteria); // Add the new criteria to first index
    const editedFee = {
      ...rest,
      criteria: editedFeeWithCriteria,
    };

    setFee(editedFee);
    dispatch(updateCurrentlyEditingFee(editedFee));

    dispatch(
      setEditLenderPayload({
        lenderId,
        body: {
          ...Object.fromEntries(Object.entries(lenderState).filter(([key]) => key !== "_id" && key !== "productTiers")),
          fees: [
            ...lenderState.fees.map((f) => {
              const { _id: feeId, ...rest } = f;
              if (feeId === currentlyEditingFee._id || !feeId) return editedFee;
              return rest;
            }),
          ],
        },
      }),
    );
    // editLender({
    //   lenderId,
    //   body: {
    //     ...Object.fromEntries(Object.entries(lender).filter(([key]) => key !== "_id" && key !== "productTiers")),
    //     fees: [
    //       ...lender.fees.map((f) => {
    //         const { _id: feeId, ...rest } = f;
    //         if (feeId === fee._id) return editedFee;
    //         return rest;
    //       }),
    //     ],
    //   },
    // });
  };

  const handleSaveChanges = () => {
      editLender(editLenderPayload).then((res) => {
        if (res?.data && !res.error) {
        handleEditClose();
        enqueueSnackbar("Fee updated successfully", {
          variant: "success",
          autoHideDuration: 5000,
        });
      } else {
        enqueueSnackbar("Failed to update fee", {
          variant: "error",
          autoHideDuration: 5000,
        });
      }
    });
  };

  return (
    <React.Fragment>
      <Grid item xs={4}>
        <Paper elevation={0} variant="outlined" sx={{ height: "100%" }}>
          <Card sx={{ height: "100%" }}>
            <CardContent>
              <Grid container xs={12} justifyContent={"end"}>
                <Stack direction="row" gap={"8px"}>
                  <Tooltip title="Clone" arrow placement="top">
                    <IconButton
                      disableRipple={true}
                      sx={{ p: 0 }}
                      onClick={handleCloneFee}
                      disabled={editLenderLoading}
                    >
                      <FileCopyTwoTone
                        fontSize="small"
                        sx={{ ":hover": { color: "black", transition: "color 150ms ease-in forwards" } }}
                      />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Edit" arrow placement="top">
                    <IconButton
                      disableRipple={true}
                      sx={{ p: 0 }}
                      onClick={handleEditOpen}
                      disabled={editLenderLoading}
                    >
                      <Edit
                        fontSize="small"
                        sx={{ ":hover": { color: "black", transition: "color 150ms ease-in forwards" } }}
                      />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Delete" arrow placement="top">
                    <IconButton
                      disableRipple={true}
                      sx={{ p: 0 }}
                      onClick={handleDeleteFee}
                      disabled={editLenderLoading}
                    >
                      <Delete
                        fontSize="small"
                        sx={{ ":hover": { color: "red", transition: "color 150ms ease-in forwards" } }}
                      />
                    </IconButton>
                  </Tooltip>
                </Stack>
              </Grid>
              <Typography variant="h5" component="div" my={1.5}>
                {lenderState?.fees[feeIndex]?.name ?? ""}
              </Typography>
              <Stack direction={"column"} rowGap={"12px"} mb={5}>
                {/* Enable just for temporary once frequency changes hide capitalized field */}
                <Typography>
                  Capitalized:
                  <Typography
                    component={"span"}
                    color={lenderState?.fees[feeIndex]?.capitalised ? "green" : "red"}
                    fontWeight={"bold"}
                  >
                    {" "}
                    {lenderState?.fees[feeIndex]?.capitalised ? "YES" : "NO"}
                  </Typography>
                </Typography>
                <Typography>
                  Fee Value:
                  <Typography component={"span"} fontWeight={"bold"}>
                    {" "}
                    {fCurrency((parseFloat(lenderState?.fees[feeIndex]?.value) ?? 0).toFixed(2) ?? 0)}
                  </Typography>
                </Typography>
                <Typography>
                  Frequency:
                  <Typography component={"span"} fontWeight={"bold"}>
                    {" "}
                    {(lenderState?.fees[feeIndex]?.frequency ?? "").charAt(0).toUpperCase() +
                      (lenderState?.fees[feeIndex]?.frequency ?? "").slice(1)}
                  </Typography>
                </Typography>
              </Stack>
              <Typography>
                Criteria:{" "}
                <Typography component={"span"} fontWeight={"bold"}>
                  {" "}
                  {(lenderState?.fees[feeIndex]?.criteria ?? []).length > 0
                    ? `${(lenderState?.fees[feeIndex]?.criteria ?? []).length}`
                    : "None"}
                </Typography>
              </Typography>
            </CardContent>
          </Card>
        </Paper>
      </Grid>
      <Dialog onClose={handleEditClose} open={editIsOpen} fullWidth maxWidth="md">
        <DialogTitle>Edit Fee Details</DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleEditClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
          disableRipple={true}
        >
          <Close />
        </IconButton>
        <DialogContent>
          <Grid container xs={12} direction={"row"} alignItems={"center"} rowGap={"24px"} mb={5}>
            <Grid container item xs={12} columnSpacing={"32px"}>
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  size="small"
                  label="Name"
                  variant="filled"
                  type="text"
                  name="name"
                  value={currentlyEditingFee?.name}
                  onChange={(e) => {
                    const editedFee = {
                      ...currentlyEditingFee,
                      [e.target.name]: e.target.name === "value" ? parseFloat(e.target.value) : e.target.value,
                    };
                    const fees = currentLenderFees.map((f, i) => {
                      const { _id: feeId, ...rest } = f;
                      const { _id: id, ...feeObj } = editedFee;

                      if (i === feeIndex) return feeObj;
                      else return rest;
                    });

                    setFee(editedFee);
                    dispatch(updateCurrentLenderFees(fees));
                    dispatch(updateCurrentlyEditingFee(editedFee));

                    handleDebounced(fees, 500);
                  }}
                />
              </Grid>
              {/* <Grid item xs={6}>
                <TextField
                  fullWidth
                  size="small"
                  label="Capitalised"
                  select
                  variant="filled"
                  name="capitalised"
                  value={currentlyEditingFee?.capitalised}
                  onChange={(e) => {
                    const editedFee = {
                      ...currentlyEditingFee,
                      [e.target.name]: e.target.name === "value" ? parseFloat(e.target.value) : e.target.value,
                    };
                    const fees = currentLenderFees.map((f, i) => {
                      const { _id: feeId, ...rest } = f;
                      const { _id: id, ...feeObj } = editedFee;

                      if (i === feeIndex) return feeObj;
                      else return rest;
                    });

                    setFee(editedFee);
                    dispatch(updateCurrentLenderFees(fees));
                    dispatch(updateCurrentlyEditingFee(editedFee));

                    handleDebounced(fees, 500);
                  }}
                >
                  <MenuItem value={true}>Yes</MenuItem>
                  <MenuItem value={false}>No</MenuItem>
                </TextField>
              </Grid> */}
            </Grid>
            <Grid container item xs={12} columnSpacing={"32px"}>
              <Grid item xs={3}>
                <TextField
                  id={"value"}
                  fullWidth
                  size="small"
                  variant="filled"
                  type="text"
                  name="value"
                  label="Fee Value"
                  onChange={(e) => {
                    const editedFee = {
                      ...currentlyEditingFee,
                      [e.target.name]: e.target.name === "value" ? parseFloat(e.target.value) : e.target.value,
                    };
                    const fees = currentLenderFees.map((f, i) => {
                      const { _id: feeId, ...rest } = f;
                      const { _id: id, ...feeObj } = editedFee;

                      if (i === feeIndex) return feeObj;
                      else return rest;
                    });

                    setFee(editedFee);
                    dispatch(updateCurrentLenderFees(fees));
                    dispatch(updateCurrentlyEditingFee(editedFee));

                    handleDebounced(fees, 500);
                  }}
                  value={currentlyEditingFee?.value}
                  InputProps={{
                    inputComponent:
                      currentlyEditingFee?.calc === FEE_CALCULATION_TYPES.Percent ? PercentageFormatCustom : NumericFormatCustom,
                  }}
                />
              </Grid>
              <Grid item xs={3}>
                <TextField
                  fullWidth
                  size="small"
                  label="Value Type"
                  select
                  variant="filled"
                  name="calc"
                  value={currentlyEditingFee?.calc || FEE_CALCULATION_TYPES.Dollar}
                  onChange={(e) => {
                    const editedFee = {
                      ...currentlyEditingFee,
                      ...(e.target.value === FEE_CALCULATION_TYPES.Percent ? { calc: FEE_CALCULATION_TYPES.Percent } : {}),
                    };

                    if (e.target.value === FEE_CALCULATION_TYPES.Dollar) delete editedFee.calc;

                    const fees = currentLenderFees.map((f, i) => {
                      const { _id: feeId, ...rest } = f;
                      const { _id: id, ...feeObj } = editedFee;

                      if (i === feeIndex) return feeObj;
                      else return rest;
                    });

                    setFee(editedFee);
                    dispatch(updateCurrentLenderFees(fees));
                    dispatch(updateCurrentlyEditingFee(editedFee));

                    handleDebounced(fees, 500);
                  }}
                >
                  <MenuItem value={FEE_CALCULATION_TYPES.Dollar}>Dollar</MenuItem>
                  <MenuItem value={FEE_CALCULATION_TYPES.Percent}>Percentage</MenuItem>
                </TextField>
              </Grid>
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  size="small"
                  select
                  label="Frequency"
                  variant="filled"
                  type="text"
                  name="frequency"
                  value={currentlyEditingFee?.frequency}
                  onChange={(e) => {
                    const editedFee = {
                      ...currentlyEditingFee,
                      [e.target.name]: e.target.name === "value" ? parseFloat(e.target.value) : e.target.value,
                    };
                    const fees = currentLenderFees.map((f, i) => {
                      const { _id: feeId, ...rest } = f;
                      const { _id: id, ...feeObj } = editedFee;

                      if (i === feeIndex) return feeObj;
                      else return rest;
                    });

                    setFee(editedFee);
                    dispatch(updateCurrentLenderFees(fees));
                    dispatch(updateCurrentlyEditingFee(editedFee));

                    handleDebounced(fees, 500);
                  }}
                >
                  {FEE_FREQUENCIES.map((frequency, index) => (
                    <MenuItem key={index} value={frequency}>
                      {frequency.charAt(0).toUpperCase() + frequency.slice(1)}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
            </Grid>
          </Grid>
          <Grid
            container
            xs={12}
            direction={"row"}
            alignItems={"center"}
            {...(fee?.criteria && fee?.criteria.length > 0 && { mb: 8 })}
          >
            <Typography mb={"8px"} mr={"16px"}>
              Criteria
            </Typography>
            <IconButton sx={{ p: 0, mt: "-5px" }} onClick={handleAddCriteria}>
              <Add />
            </IconButton>
          </Grid>

          {currentlyEditingFee?.criteria && currentlyEditingFee?.criteria.length > 0 && (
            <Grid
              container
              item
              xs={12}
              direction={"row"}
              columnSpacing={"24px"}
              rowSpacing={"36px"}
              {...(currentlyEditingFee.criteria.length > 4 && { maxHeight: "370px", sx: { overflowY: "scroll" } })}
            >
              {currentlyEditingFee?.criteria.map((feeCriteria, index) => (
                <React.Fragment key={index}>
                  <CriteriaContent
                    key={index}
                    criteria={feeCriteria}
                    index={index}
                    lenderDetail={fee}
                    lender={lenderState}
                    editLender={editLender}
                    editLenderLoading={editLenderLoading}
                    keyString="fees"
                    setFee={setFee}
                    feeIndex={feeIndex}
                  />
                  {(currentlyEditingFee?.criteria ?? []).length - 1 !== index && (
                    <Grid item xs={12}>
                      <Divider flexItem />
                    </Grid>
                  )}
                </React.Fragment>
              ))}
            </Grid>
          )}
          <Grid container justifyContent="flex-end">
            <Button variant="contained" onClick={handleSaveChanges} sx={{ mt: "24px" }} disabled={editLenderLoading}>
              {editLenderLoading ? "Saving..." : "Save Changes"}
            </Button>
          </Grid>
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
};

export default Fee;
